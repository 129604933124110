.container-center {
    text-align: center;
    width: auto;
    margin-left: 0em;
    margin-right: 0em;
    margin-top: 0.25rem;
    height: 100vh;
}

.col-left {
    background: #212936;
    height: 100vh;
    padding: 0;
    max-width: 16.67%;
}

.col-middle {
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 1em;
    max-width: 58.33%;
}

.timeline-row {
    padding-bottom: 0.5em;
    border-bottom: 1px solid rgba(76, 76, 76, 0.32);
    margin-bottom: 0.5em;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(153, 153, 153, 0.5);
    box-shadow: 0 0 1px rgba(186, 186, 186, 0.5);
}

.col-right {
    height: 98vh;
    padding-left: 0;
    padding-right: 0;
    /*padding-top: 0.4em;*/
    margin: auto;
    max-width: 25%;
    overflow-y: scroll;
}

.component {
    background: #efefef;
    border-radius: 9px;
    padding: 0.5em;
}

.component-right {
    background: #E8E8E8;
    border-radius: 9px;
    /*margin: 0.4em;*/
    padding: 0.75em;
}

.round-icon {
    background: #C4C4C4;
    border-radius: 5px;
    padding: 1.2em !important;
    margin: auto;
}

.message-box {
    background: #FFFFFF;
    padding: 1em;
    border-radius: 10px;
}

.message-text {
    font-family: Ubuntu Mono, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
}

.monitor-station-title {
    background: #17a2b8;
    border-radius: 12px;
    max-width: 20em;
    padding: 0.8em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.monitor-station-title-text {
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: center;
    margin: auto;
}

.monitor-card {
    background: #F9F9F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 1.5em;
    padding: 0.5em 1em 1.5em 1em;
    /*padding-bottom: 0.5em;*/
}

.gauge-chart {
    margin: 1em 0.25em;
    padding: 0.5em 0em;
    background-color: rgba(152, 198, 232, 0.6);
    border-radius: 1em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 20vh;
}

.gauge-style {
    width: 100vh;
}

[type='radio'] {
    display: none;
}

.monitor-card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    /* or 105% */

    text-align: center;
    letter-spacing: 0.1px;

    color: #000000;
    margin-top: 0.7em;
    margin-bottom: 1em;
    margin-left: 0.3em;

    display: inline-table;
}

.light-setting-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    /* or 105% */

    text-align: center;
    letter-spacing: 0.1px;

    color: #000000;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0.3em;

    display: inline-table;
}

.light-setting-label {
    font-size: 16px;
    font-weight: 500;
}

.light-setting-lane-component {
    border-bottom: 1px solid rgba(76, 76, 76, 0.32);
    padding: 0.2em 0em
}

.monitor-unlock-text {
    padding-top: 8px;
    margin: 0px;
    text-align: right;
    font-size: 18px;
}

.monitor-table-left {
    text-align: right;
    font-weight: bolder;
    font-size: 16px;
    line-height: 21px;
    /* or 117% */

    letter-spacing: 0.1px;

    color: #000000;
}

.monitor-table-right {
    text-align: left;
    font-size: 16px;
    text-decoration-line: underline;
}

.btn-primary {
    color: #ffffff;
    background: linear-gradient(0deg, #2196F3, #2196F3);
    border-color: #3880f6;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-danger {
    color: #ffffff;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-info {
    border-radius: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-secondary {
    border-radius: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #17a2b8;
}

input:focus + .slider {
    box-shadow: 0 0 1px #17a2b8;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.monitor-input-time {
    border: none;
    border-bottom: 2px solid #656565;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.58);
}

.monitor-input-time-normal {
    border: 1px solid rgba(184, 184, 184, 0.57);
    width: 100%;
    text-align: center;
    background-color: rgb(255, 255, 255);
}

.monitor-input-time-active {
    border: none;
    border-bottom: 2px solid gray;
    width: 100%;
    background-color: transparent;
    text-align: center;
}

.btn-sync {
    float: left;
}

.tag-left {
    float: left;
    position: absolute;
    left: 15.5em;
    top: 29em;
    z-index: 1000;
    padding: 5px;
}

.tag-counter-left {
    float: left;
    position: absolute;
    left: 23.5em;
    top: 29em;
    z-index: 1000;
    padding: 5px;
}

.tag-text-left {
    float: left;
    position: absolute;
    left: 13.1em;
    top: 15.7em;
    z-index: 1000;
    /*color: #66FF3F;*/
    font-size: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    font-weight: bolder;
}

.tag-walking-left {
    float: left;
    position: absolute;
    left: 20.2em;
    top: 25.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-railway-left {
    float: left;
    position: absolute;
    left: 20.2em;
    top: 22em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-left-left {
    float: left;
    position: absolute;
    left: 23.5em;
    top: 18.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-go-ahead-left {
    float: left;
    position: absolute;
    left: 23.5em;
    top: 22em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-right-left {
    float: left;
    position: absolute;
    left: 23.5em;
    top: 25.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-top {
    float: left;
    position: absolute;
    left: 27em;
    top: 5em;
    z-index: 1000;
    padding: 5px;
}

.tag-walking-top {
    float: left;
    position: absolute;
    left: 30.5em;
    top: 9.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-text-top {
    float: left;
    position: absolute;
    left: 14.9em;
    top: 7.2em;
    z-index: 1000;
/*color: #FF0909;*/
    font-size: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    font-weight: bolder;
}

.tag-text-common {
    float: left;
    position: absolute;
    left: 0.5em;
    top: 0.25em;
    z-index: 1000;
    /*color: #FF0909;*/
    font-size: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    font-weight: bolder;
}

.tag-counter-top {
    float: left;
    position: absolute;
    left: 27em;
    top: 13em;
    z-index: 1000;
    padding: 5px;
}

.tag-railway-top {
    float: left;
    position: absolute;
    left: 34em;
    top: 9.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-left-top {
    float: left;
    position: absolute;
    left: 30.5em;
    top: 13em;
    z-index: 1000;
    padding: 5px;
}

.tag-go-ahead-top {
    float: left;
    position: absolute;
    left: 34em;
    top: 13em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-right-top {
    float: left;
    position: absolute;
    left: 37.5em;
    top: 13em;
    z-index: 1000;
    padding: 5px;
}

.tag-normal {
    float: left;
    position: absolute;
    z-index: 1000;
    padding: 5px;
}

.tag-right {
    float: left;
    position: absolute;
    left: 46.7em;
    top: 16.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-walking-right {
    float: left;
    position: absolute;
    left: 46.7em;
    top: 20em;
    z-index: 1000;
    padding: 5px;
}

.tag-text-right {
    float: left;
    position: absolute;
    left: 23.7em;
    top: 9em;
    z-index: 1000;
    /*color: #66FF3F;*/
    font-size: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    font-weight: bolder;
}

.tag-counter-right {
    float: left;
    position: absolute;
    left: 43.4em;
    top: 16.5em;
    z-index: 1000;
    padding: 5px;
}

.tag-railway-right {
    float: left;
    position: absolute;
    left: 46.7em;
    top: 23.3em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-left-right {
    float: left;
    position: absolute;
    left: 43.4em;
    top: 20em;
    z-index: 1000;
    padding: 5px;
}

.tag-go-ahead-right {
    float: left;
    position: absolute;
    left: 43.4em;
    top: 23.3em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-right-right {
    float: left;
    position: absolute;
    left: 43.4em;
    top: 26.7em;
    z-index: 1000;
    padding: 5px;
}

.tag-bottom {
    float: left;
    position: absolute;
    left: 39.8em;
    top: 36em;
    z-index: 1000;
    padding: 5px;
}

.tag-walking-bottom {
    float: left;
    position: absolute;
    left: 36.3em;
    top: 36em;
    z-index: 1000;
    padding: 5px;
}

.tag-text-bottom {
    float: left;
    position: absolute;
    left: 21.7em;
    top: 17.7em;
    z-index: 1000;
    /*color: #FF0909;*/
    font-size: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    font-weight: bolder;
}

.tag-counter-bottom {
    float: left;
    position: absolute;
    left: 39.8em;
    top: 32.7em;
    z-index: 1000;
    padding: 5px;
}

.tag-railway-bottom {
    float: left;
    position: absolute;
    left: 33em;
    top: 36em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-left-bottom {
    float: left;
    position: absolute;
    left: 29.8em;
    top: 32.7em;
    z-index: 1000;
    padding: 5px;
}

.tag-go-ahead-bottom {
    float: left;
    position: absolute;
    left: 33.1em;
    top: 32.7em;
    z-index: 1000;
    padding: 5px;
}

.tag-turn-right-bottom {
    float: left;
    position: absolute;
    left: 36.3em;
    top: 32.7em;
    z-index: 1000;
    padding: 5px;
}

.nav-item {
    background-color: transparent;
    color: #56657F;
    font-size: 1.2em;
    padding: 0.8em 1em;
}

/*.nav-item.active {*/
/*    background-color: white !important;;*/
/*}*/

.list-group-item.active {
    background-color: #2B3648;
    color: #1976D2;
    border-color: transparent;
    border-radius: 0;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.station-label-sign {
    background-color: rgba(0, 0, 0, 0.69);
    font-weight: normal;
    font-size: 22px;
    color: white;
    padding: 0.25em 0.5em;
    border-radius: 0.75em;
}

/*.btn-change {*/
/*    display: block;*/
/*}*/

.component-mobile {
    display: none;
}

.btn-mobile {
    padding: 1.5em;
    display: none;
}

.error-status-box {
    margin-top: 0.5em;
    border: 1px solid rgba(76, 76, 76, 0.32);
    border-radius: 10px;
    padding: 0.5em 1em;
    text-align: left;
    max-height: 40em;
    overflow-y: scroll;
}

.signal-item {
    border: 1px solid rgba(76, 76, 76, 0.32);
    border-radius: 10px;
    padding: 0.3em 0 0.1em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 0 0.5em;
}

.mobile-sign-signal {
    display: none;
}

@media(max-width: 768px) {
    .col-left {
        display: none;
    }
    .col-middle {
        display: none;
    }
    .col-right {
        max-width: 100%;
    }
    .btn-sync {
        display: none;
    }
    .btn-change {
        display: none;
    }
    .component-mobile {
        display: block;
    }
    .btn-mobile {
        padding: 1.5em;
        display: block;
    }
    .mobile-sign-signal {
        display: block;
    }
}

@media(max-width: 375px) {
    .component {
        padding: 1em;
    }
    .monitor-card {
        padding: 1em 0.5em;
        /*padding-bottom: 0.5em;*/
    }
}